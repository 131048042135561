import React from 'react'
import OrangeDsIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import ScrollTo from 'src/components/ScrollTo'
import { Section } from './style'

function SeparateFinances () {
  return (
    <Section className='py-5'>
      <div className='container'>
        <div className='row align-items-center justify-content-between'>
          <div className='col-12 col-md-6'>
            <img
              width='100%'
              src='https://central-imagens.bancointer.com.br/images-without-small-versions/planilha-mulher-mei/image.webp'
              alt='Correntista Inter Empresas sorrindo enquanto segura seu notebook.'
            />
          </div>
          <div className='col-12 col-md-6'>
            <h2 className='fs-20 fs-md-24 fs-lg-30 fs-xl-32 lh-25 lh-md-30 lh-lg-35 lh-xl-40 fw-500'>Separar suas finanças pessoais das empresariais pode ser muito simples!</h2>
            <h3 className='fs-14 fs-md-18 fs-lg-18 lh-17 lh-md-22 lh-lg-24 text-grayscale--500 mb-4 fw-400'>
              Tenha em mãos uma ferramenta que te ajuda a tomar decisões diárias no seu negócio.
            </h3>
            <ul>
              <li className='mb-3'>
                <div className='d-flex'>
                  <OrangeDsIcon size='LG' color='#FF7A00' icon='search' />
                  <p className='mb-0 mt-1 ml-3 fs-14 fs-md-16 lh-17 lh-md-20 text-grayscale--500'>Garanta mais clareza sobras vendas efetuadas</p>
                </div>
              </li>
              <li className='mb-3'>
                <div className='d-flex'>
                  <OrangeDsIcon size='LG' color='#FF7A00' icon='deposit-by-boleto' />
                  <p className='mb-0 mt-1 ml-3 fs-14 fs-md-16 lh-17 lh-md-20 text-grayscale--500'>Controle todas as suas despesas mensais</p>
                </div>
              </li>
              <li className='mb-3'>
                <div className='d-flex'>
                  <OrangeDsIcon size='LG' color='#FF7A00' icon='settings' />
                  <p className='mb-0 mt-1 ml-3 fs-14 fs-md-16 lh-17 lh-md-20 text-grayscale--500'>Automatize a visão de fluxo de caixa da sua empresa</p>
                </div>
              </li>
              <li className='mb-3'>
                <div className='d-flex'>
                  <OrangeDsIcon size='LG' color='#FF7A00' icon='spending' />
                  <p className='mb-0 mt-1 ml-3 fs-14 fs-md-16 lh-17 lh-md-20 text-grayscale--500'>Conte com gráficos de análise e veja seu negócio crescer</p>
                </div>
              </li>
            </ul>
            <div className='col-12 text-center'>
              <ScrollTo
                to='#first-section'
                section='dobra_02'
                sectionName='Como abrir uma empresa? Manual completo'
                elementName='Baixar Agora'
                title='Baixar Agora'
              >
                <button
                  className='btn btn-orange bg-orange--extra text-white text-none rounded-2 mt-4'
                >
                  Baixar Agora
                </button>
              </ScrollTo>
            </div>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default SeparateFinances
