import styled from 'styled-components'

export const Section = styled.section`
  a {
    width: 100%;
    max-width: 100%;
  }

  ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
`
