import React, { useState, MouseEvent, ChangeEvent } from 'react'
import axios from 'axios'
import { useForm } from 'react-hook-form'
import * as Masks from 'inter-frontend-lib-util-form/lib/masks'
import * as Validations from 'inter-frontend-lib-util-form/lib/validations'
import * as URLS from 'src/config/api/Urls'
import useDomReady from 'src/hooks/window/useDomReady'
import { Modal } from 'src/components/Modal'

import { FormContainer, Checkbox } from './style'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'

import Error from './status/error/_index'
import Sent from './status/sent/_index'

type BaixarEbookFormProps = {
  dataLayer?: IDataLayerParams;
}

interface IFormValues {
  nome: string;
  email: string;
  celular: string;
  CpfCnpj: string;
  cliente?: string;
  range: string;
}

function BaixarEbookForm ({ dataLayer }: BaixarEbookFormProps) {
  const { register, errors, handleSubmit, setValue, reset }: Pick<ReturnType<typeof useForm>, 'register' | 'errors' | 'handleSubmit' | 'setValue' | 'reset'> = useForm()
  const domReady = useDomReady()
  const [ sendDatalayerEvent ] = useDataLayer()
  const [ loading, setLoading ] = useState(false)
  const [ accept, setAccept ] = useState(false)
  const [ isClient, setIsClient ] = useState(false)

  const [ isOpenSent, setIsOpenSent ] = useState(false)
  const [ isOpenError, setIsOpenError ] = useState(false)

  const sendForm = async (data: IFormValues) => {
    const isClientFormatted = isClient ? 'Sim' : 'Nao'

    setLoading(true)

    const formData = [ {
      campanha: 'Controle Financeiro MEI',
      nome: data.nome,
      CpfCnpj: data.CpfCnpj.replace(/\D/g, ''),
      email: data.email,
      conteudo01: isClientFormatted,
      aceite: accept,
    } ]

    try {
      await axios.post(`${URLS.CONTACT_FORM_POST_V5}/lp-mktcloud-inter`, formData, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      setIsOpenSent(true)
      sendDatalayerEvent({
        section: dataLayer ? 'm_' + dataLayer.section : 'm_dobra_0 ',
        section_name: dataLayer ? dataLayer.section_name : '',
        element_search: `Possui CNPJ ativo? - ${isClientFormatted}`,
        element_action: 'submit',
        element_name: 'Enviar',
        element_previous: dataLayer ? `${dataLayer.element_name}, ${dataLayer.section}` : '',
        step: 'success',
      })
      reset()
    } catch (e) {
      setIsOpenError(true)
      setLoading(false)
    }
  }

  const handleReturn = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    setIsOpenSent(false)
    setIsOpenError(false)
    setLoading(false)
  }

  const sentModal = domReady && (
    <Modal isModalOpen={isOpenSent} setIsModalOpen={setIsOpenSent} locationToRender={document.body}>
      <Sent handleReturn={handleReturn} />
    </Modal>
  )

  const errorModal = domReady && (
    <Modal isModalOpen={isOpenError} setIsModalOpen={setIsOpenError} locationToRender={document.body}>
      <Error handleReturn={handleReturn} />
    </Modal>
  )

  return (
    <>
      <FormContainer className='py-md-4 px-3 py-xl-5'>
        {sentModal}
        {errorModal}
        <form name='open_account' onSubmit={handleSubmit(sendForm)}>
          <div className='form--default'>
            <div className='row mx-0'>
              <div className='col-12 pt-3'>
                <p className='fs-16 lh-20 fs-md-24 lh-md-30 fw-600 text-orange--extra text-center mb-3'>
                  Baixe sua planilha gratuitamente
                </p>
                <p className='fs-14 lh-16 fs-md-16 lh-md-19 text-grayscale--500 text-lg-center'>Simplifique a gestão financeira do seu negócio.</p>
              </div>

              <div className={`col-12 pb-3 d-flex flex-column ${errors.nome && 'error'}`}>
                <label htmlFor='nome' className='fs-14 lh-17 text-grayscale--500'>Nome</label>
                <input
                  ref={register({
                    required: 'Nome obrigatório',
                  })}
                  name='nome'
                  id='nome'
                  type='text'
                  maxLength={100}
                  className={`${errors.nome ? 'error' : ''}`}
                  placeholder={errors.nome ? errors.nome.message : 'Digite seu nome'}
                />
              </div>

              <div className={`col-12 pb-3 d-flex flex-column ${errors.email && 'error'}`}>
                <label htmlFor='email' className='fs-14 lh-17 text-grayscale--500'>E-mail</label>
                <input
                  ref={register({
                  required: 'E-mail obrigatório',
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: 'Insira um e-mail válido',
                  },
                })}
                  name='email'
                  id='email'
                  type='text'
                  className={`${errors.email ? 'error' : ''}`}
                  placeholder={errors.email ? errors.email.message : 'Digite o seu e-mail'}
                />
              </div>

              <div className={`col-12 pb-3 d-flex flex-column ${errors.CpfCnpj && 'error'}`}>
                <label htmlFor='CpfCnpj' className='fs-14 lh-17 text-grayscale--500'>CPF</label>
                <input
                  ref={register({
                    required: 'CPF obrigatório',
                    validate: {
                      isCpf: (value: string) => Validations.cpf(value) || 'CPF Inválido',
                    },
                  })}
                  name='CpfCnpj'
                  id='CpfCnpj'
                  type='tel'
                  className={`${errors.CpfCnpj ? 'error' : ''}`}
                  placeholder={errors.CpfCnpj ? errors.CpfCnpj.message : 'Digite o seu CPF'}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => setValue('CpfCnpj', Masks.MaskCPF(event.currentTarget.value))}
                />
              </div>

              <div
                className='col-12 d-flex flex-column'
              >
                <label htmlFor='estado' className='fs-14 lh-17 text-grayscale--500 mb-md-0'>Você possui um CNPJ ativo?</label>
                <div className='d-flex justify-content-start pt-1 pt-md-0'>
                  <label className='radio-contato fw-400' htmlFor='cliente'>
                    <input
                      ref={register({
                        required: 'Informação obrigatória',
                      })}
                      type='radio'
                      id='cliente'
                      name='cliente'
                      value='sim'
                      onClick={() => setIsClient(true)}
                    />
                    <span className={`radio-check ${errors.cliente && 'error'}`} />
                    Sim
                  </label>
                  <label className='radio-contato fw-400' htmlFor='naoCliente'>
                    <input
                      ref={register({
                      required: 'Informação obrigatória',
                    })}
                      type='radio'
                      id='naoCliente'
                      name='cliente'
                      value='nao'
                      onClick={() => setIsClient(false)}
                    />
                    <span className={`radio-check ${errors.cliente && 'error'}`} />
                    Não
                  </label>
                </div>
                {errors.cliente && <p className='fs-12 mt-1 mb-0 pt-1 text-grayscale--500 text-left'><i>{errors.cliente.message}</i></p>}
              </div>

              <div className='col-12 d-flex col-xl-10 flex-column mt-4'>
                <Checkbox onClick={() => setAccept(!accept)}>
                  <input
                    type='checkbox'
                    className='form-input-check'
                    checked={accept}
                  />
                  <label className='form-label-check fs-12 lh-15 pt-lg-1 text-grayscale--500'>
                    Autorizo o Inter a tratar meus dados pessoais para envio de comunicações sobre seus produtos e serviços e também estou de acordo com a{' '}
                    <a
                      className='fw-700'
                      title='Acessar Política de Privacidade do Inter'
                      href='https://inter.co/politica-de-privacidade/privacidade/'
                      target='_blank'
                      rel='noreferrer'
                    >
                      Política de Privacidade
                    </a>.
                  </label>
                </Checkbox>
              </div>

              <div className='col-12 text-center mb-4 mb-md-0'>
                <button
                  type='submit' title='Enviar contato'
                  disabled={!accept || loading}
                  className='btn btn-orange--extra fs-14 fw-600 rounded-2 mx-auto text-grayscale--500 text-none mb-4 mb-xl-0'
                >
                  {loading ? 'Enviando...' : 'Enviar'}
                </button>
              </div>
            </div>
          </div>
        </form>
      </FormContainer>
    </>
  )
}

export default BaixarEbookForm
