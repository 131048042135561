import styled from 'styled-components'

export const Section = styled.section`
  background-color: #FFF2E7;
  h2 {
    color: #EA7100;
  }

  button {
    width: 100%;
    max-width: 100%;
  }

  ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
`
