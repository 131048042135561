import React from 'react'
import { Section } from './style'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'
import BaixarEbookForm from '../../components/BaixarEbookForm/_index'
import ImageWebp from 'src/components/ImageWebp'

function Hero () {
  const dataLayer: IDataLayerParams = {
    section: 'dobra_01',
    section_name: 'Planilha de controle financeiro MEI grátis',
    element_action: 'click button',
    element_name: 'Baixar agora',
  }

  return (
    <Section id='first-section' className='py-5 d-lg-flex align-items-lg-center'>
      <div className='container'>
        <div className='row align-items-md-center'>
          <div className='col-12 col-md-6 col-lg-5 mb-4 mb-md-0'>
            <div className='d-flex justify-content-center'>
              <ImageWebp
                pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/planilha-mei-dobra1/image.webp'
                altDescription='Telas da planilha de controle financeiro MEI'
                arrayNumbers={[ 231, 276, 372, 445 ]}
                arrayNumbersHeight={ [ 211, 259, 328, 398 ] }
              />
            </div>
            <h1 className='fs-20 lh-25 fs-md-32 lh-md-40 fs-xl-40 lh-xl-50 fw-600 mb-3 mt-md-4 text-white fw-500'><span className='d-block d-xl-inline'>Planilha de controle</span> financeiro MEI grátis</h1>
            <p className='fs-14 lh-17 fs-md-18 lh-md-22 text-grayscale--500 my-lg-4'>
              Garanta mais clareza sobre o fluxo de caixa do seu negócio.
            </p>
          </div>

          <div className='col-12 col-md-6 offset-lg-1'>
            <BaixarEbookForm dataLayer={dataLayer} />
          </div>
        </div>
      </div>
    </Section>
  )
}

export default Hero
