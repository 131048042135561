import React from 'react'
import { Wrapper } from './style'
import Layout from 'src/layouts/BaseLayout'
import pageContext from './pageContext.json'
import Hero from './sections/hero/_index'
import SeparateFinances from './sections/separar-finanças/_index'
import Simplify from './sections/simplifique/_index'
import useUtms from 'src/hooks/useUtms'

function SpreadsheetMei () {
  const utmLink = useUtms({ link: `${process.env.OPEN_ACCOUNT_PJ}`, campanha: '' })

  const openAccountPJFormModal = () => {
    window.location.href = utmLink
  }

  return (
    <Wrapper>
      <Layout pageContext={pageContext} handleHeaderOpenAccount={() => openAccountPJFormModal()}>
        <Hero />
        <SeparateFinances />
        <Simplify />
      </Layout>
    </Wrapper>
  )
}

export default SpreadsheetMei
