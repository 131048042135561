import React, { MouseEvent } from 'react'

import successImg from '../../assets/images/success.png'

import { FormSent, CloseButton } from '../style'
import Close from 'inter-frontend-svgs/lib/v2/navigation/close'

type OpenAccountFormProps = {
  handleReturn: (e: MouseEvent<HTMLButtonElement>) => void;
};

const Sent = ({
  handleReturn,
}: OpenAccountFormProps) => {
  return (
    <FormSent
      className='d-flex position-relative align-items-center justify-content-center py-3 px-3'
    >
      <CloseButton className='border-0 bg-transparent pt-4' onClick={handleReturn}>
        <Close width='20' height='20' color='orange--extra' />
      </CloseButton>

      <div className='col-12 col-md-10 col-lg-12 text-center'>
        <div className='row align-items-center justify-content-center'>
          <div className='col-12 mt-2 mt-lg-4'>
            <div className='col-12 mb-3 mx-auto'>
              <img
                src={successImg}
                alt='Sucesso'
              />
            </div>
            <p className='fs-18 lh-20 fs-md-24 lh-md-30 text-center text-grayscale--500 fw-600 mb-3'>
              Parabéns, seu cadastro foi realizado com sucesso!
            </p>
            <p className='fs-14 lh-17 text-center text-grayscale--500 mb-3 mb-lg-4'>
              Mas caso não possa realizar o download agora,
              fique tranquilo! Em pouco tempo você receberá o eBook
              na sua caixa de e-mail.
            </p>

            <a
              href='https://marketing.bancointer.com.br/empresas/documentos/Planilha_de_Controle_Financeiro_MEI.xlsx'
              target='blank'
              className='btn btn-orange--extra fs-14 fw-600 rounded-2 mx-auto text-grayscale--100 text-none mw-100'
              onClick={() => handleReturn}
            >
              Fazer download
            </a>
          </div>
        </div>
      </div>
    </FormSent>
  )
}

export default Sent
