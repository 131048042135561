import { device } from 'src/styles/breakpoints'
import { grayscale } from 'src/styles/colors'

import BackgroundVector from '../../assets/images/background-vector-planilha-de-controle.png'

import styled from 'styled-components'

export const Section = styled.section`
  background-color: #EA7100;
  background-repeat: no-repeat;
  background-size: initial;

  @media ${device.tablet} {
    background-image: url(${BackgroundVector});
    background-position: 450px 88px;
  }

  @media ${device.desktopLG} {
    background-position: 570px 58px;
  }

  @media ${device.desktopXL} {
    background-position: 796px 84px;
  }

  @media ${device.desktopXXXL} {
    background-position: 1191px 132px;
  }

  .form--default label {
    color: ${grayscale[500]};
  }

  padding: 40px 0;
`
