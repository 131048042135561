import styled from 'styled-components'
import { gray, grayscale, orange, white } from 'src/styles/colors'
import { breakpoints, device } from 'src/styles/breakpoints'

export const FormContainer = styled.div`
  border-radius: 16px;
  background-color: #FFFFFF;
  input {
    height: 32px;
    border-radius: 8px;

    &.error {
      background: #F7D0C8 !important;
    }

    @media (min-width: ${breakpoints.md}) {
      height: 40px;
      min-width: 100%;
    }

    @media (min-width: ${breakpoints.lg}) {
      min-width: 100%;
    }
  }

  button {
    @media (min-width: ${breakpoints.lg}) {
     min-width: 100%;
    }
  }

  .form--default .radio-contato input:checked + .radio-check {
    border-color: ${grayscale[500]};
  }

  .form--default .radio-contato input:checked + .radio-check::after {
    background: ${grayscale[500]};
  }

  .form--default .radio-contato .radio-check {
    border-color: ${grayscale[500]};

    &.error {
      background: #F7D0C8 !important;
    }
  }

  .radio-contato {
    color: ${grayscale[500]};
  }
`

export const Checkbox = styled.div`
  margin-bottom: 36px;

  input {
    display: contents;
  }

  .form-label-check {
    position: relative;
    padding-left: 30px;
    margin-bottom: 0;

    &::before,
    &::after {
      display: block;
      position: absolute;
      content: "";
    }

    &::before {
      border: 2px solid ${grayscale[500]};
      border-radius: 3px;
      height: 20px;
      left: 0;
      top: 2px;
      width: 20px;
    }

    a {
      &:hover {
        color: ${gray['600']};
      }
    }
  }

  .form-input-check {
    height: 0 !important;
    opacity: 0;
    width: auto;

    &:disabled ~ label {
      opacity: 0.6;
    }
    &:checked ~ label {
      &::before {
        background: linear-gradient(
          7.83deg,
          ${orange.dark} 0%,
          ${orange.base} 100%
        );
        border: 1px solid ${grayscale[500]};
      }
      &::after {
        left: 7px;
        top: 5px;
        width: 6px;
        height: 10px;
        border: solid ${white};
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);

        @media ${device.tablet} {
          top: 5px;
        }
      }
    }
  }
`
