import React from 'react'
import { Section } from './style'
import OrangeIcon from 'src/components/UI/MarkdownIcon/InterCoIcons'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

function Simplify () {
  const [ sendDatalayerEvent ] = useDataLayer()
  const redirectUrl = 'https://conta-digital-pj.inter.co/login?utm_source=ebook&utm_medium=botao&utm_campaign=aquisicao_ebook_pj_botao&utm_term=conta-digital-pj&utm_content=planilha_mei'

  return (
    <Section className='bg-white py-5'>
      <div className='container'>
        <div className='row align-items-center justify-content-between'>
          <div className='col-12 col-md-6 order-1 order-md-2'>
            <img
              width='100%'
              src='https://central-imagens.bancointer.com.br/images-without-small-versions/planilha-mei-dobra3/image.webp'
              alt='Cartão PJ ao lado de um celular com o Super App Inter Empresas em destaque.'
            />
          </div>
          <div className='col-12 col-md-6 order-2 order-md-1'>
            <h2 className='fs-20 fs-md-24 fs-lg-30 fs-xl-32 lh-25 lh-md-30 lh-lg-35 lh-xl-40 fw-500 text-orange--extra'>
              Simplifique: abra sua conta MEI 100% digital e gratuita
            </h2>
            <h3 className='fs-14 fs-md-18 fs-lg-18 lh-17 lh-md-22 lh-lg-24 text-grayscale--500 mb-4 fw-400'>
              Leve o seu negócio ainda mais longe com o Inter Empresas.
            </h3>
            <ul>
              <li className='mb-3'>
                <div className='d-flex'>
                  <OrangeIcon size='LD' color='#FF7A00' icon='pix-outline' />
                  <p className='mb-0 mt-1 ml-3 fs-14 fs-md-16 lh-17 lh-md-20 text-grayscale--500'>Pix mensais ilimitados e sem taxas</p>
                </div>
              </li>
              <li className='mb-3'>
                <div className='d-flex'>
                  <OrangeIcon size='LG' color='#FF7A00' icon='card' />
                  <p className='mb-0 mt-1 ml-3 fs-14 fs-md-16 lh-17 lh-md-20 text-grayscale--500'>Cartão de crédito sem anuidade</p>
                </div>
              </li>
              <li className='mb-3'>
                <div className='d-flex'>
                  <OrangeIcon size='MD' color='#FF7A00' icon='inter-loop' />
                  <p className='mb-0 mt-1 ml-3 fs-14 fs-md-16 lh-17 lh-md-20 text-grayscale--500'>Programa de pontos gratuito</p>
                </div>
              </li>
              <li className='mb-3'>
                <div className='d-flex'>
                  <OrangeIcon size='LD' color='#FF7A00' icon='investments' />
                  <p className='mb-0 mt-1 ml-3 fs-14 fs-md-16 lh-17 lh-md-20 text-grayscale--500'>Plataforma de investimentos completa</p>
                </div>
              </li>
              <li className='mb-3'>
                <div className='d-flex'>
                  <OrangeIcon size='LD' color='#FF7A00' icon='machine' />
                  <p className='mb-0 mt-1 ml-3 fs-14 fs-lg-16 lh-lg-22 lh-20 text-grayscale--500'>Maquininha com as melhores taxas do mercado</p>
                </div>
              </li>
              <li className='mb-3'>
                <div className='d-flex'>
                  <OrangeIcon size='LG' color='#FF7A00' icon='barcode' />
                  <p className='mb-0 mt-1 ml-3 fs-14 fs-lg-16 lh-lg-22 lh-20 text-grayscale--500'>Emissão de boletos gratuitos todo mês</p>
                </div>
              </li>
              <li className='mb-3'>
                <div className='d-flex'>
                  <OrangeIcon size='LG' color='#FF7A00' icon='shopping-bag' />
                  <p className='mb-0 mt-1 ml-3 fs-14 fs-lg-16 lh-lg-22 lh-20 text-grayscale--500'>Marketplace e viagens com cashback</p>
                </div>
              </li>
            </ul>
            <p className='fs-14 fs-lg-16 lh-lg-22 lh-20 text-grayscale--500'>E muito mais!</p>
            <a
              href={redirectUrl}
              className='btn btn--lg btn-orange--extra rounded-2 fs-14 fw-600 text-none mt-3'
              onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_03',
                  section_name: 'Simplifique: abra sua conta MEI 100% digital e gratuita',
                  element_action: 'click button',
                  element_name: 'Abrir conta MEI gratuita',
                  redirect_url: redirectUrl,
                })
              }}
            >
              Abrir conta MEI gratuita
            </a>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default Simplify
